script + .dx-overlay-wrapper {
  .dx-list-item-content {
    padding: 0 !important;
    text-align: center;
    .dx-button {
      width: 100%;
      border: none;
      .dx-button-content {
        padding: 5px;
      }
    }
  }
}
